exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-contacts-js": () => import("./../../../src/pages/contacts.js" /* webpackChunkName: "component---src-pages-contacts-js" */),
  "component---src-pages-ext-[unique]-js": () => import("./../../../src/pages/ext/[unique].js" /* webpackChunkName: "component---src-pages-ext-[unique]-js" */),
  "component---src-pages-index-b-js": () => import("./../../../src/pages/index-b.js" /* webpackChunkName: "component---src-pages-index-b-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-js": () => import("./../../../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-perspective-js": () => import("./../../../src/pages/perspective.js" /* webpackChunkName: "component---src-pages-perspective-js" */),
  "component---src-pages-projects-[id]-js": () => import("./../../../src/pages/projects/[id].js" /* webpackChunkName: "component---src-pages-projects-[id]-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-tender-js": () => import("./../../../src/pages/tender.js" /* webpackChunkName: "component---src-pages-tender-js" */)
}

