// custom typefaces
import "@fontsource-variable/montserrat"
import "@fontsource/merriweather"

import 'viewerjs-react/dist/index.css'
// normalize CSS across browsers
// import "./src/normalize.css"

// custom CSS styles
// import "./src/style.css"

import "bootstrap/dist/css/bootstrap.min.css"

import "./static/styles/index.css"
import "./static/styles/feed.css"
import "./static/styles/override-color.css"
import "./static/styles/timeline-slider.css"
// import "./static/styles/god-rays.css"
import "./static/styles/text-shimmer.css"

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// Highlighting for code blocks
import "prismjs/themes/prism.css"
import 'react-modern-drawer/dist/index.css'

// react slick
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'; 
import "./static/styles/timeline.css"

import React from 'react';
import RootWrapper from "./src/components/RootWrapper"

export const wrapPageElement = ({ element, props }) => {
  return <RootWrapper {...props}>{element}</RootWrapper>;
};