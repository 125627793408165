import React from 'react'
import { Helmet } from "react-helmet";
import { ParallaxProvider } from 'react-scroll-parallax';

// import anime from 'animejs';
import {
  RecoilRoot,
} from 'recoil';
// import { navigate } from 'gatsby';

// import logo from '../../static/assets/Comp_1_4.gif'

const RootWrapper = ({ children }) => {

  const [justOpened, setJustOpened] = React.useState(false)

  // React.useEffect(() => {
    // anime({
    //   targets: '#backdrop-loading',
    //   keyframes: [
    //     { opacity: 1 },
    //     { opacity: 0 },
    //   ],
    //   duration: 9000
    // });

    // navigate('/')
    // setTimeout(() => {
    //   setJustOpened(false)
      // navigate('/projects', {
      //   state:  { justOpened: true }
      // })
    // }, 9000)
  // }, [])

  return (
    <div>
      <Helmet>
        {/* <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css" 
                rel="stylesheet" integrity="sha384-T3c6CoIi6uLrA9TneNEoa7RxnatzjcDSCmG1MXxSR1GAsXEV/Dwwykc2MPK8M2HN" crossOrigin="anonymous"/> */}
        <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.bundle.min.js" integrity="sha384-C6RzsynM9kWDrMNeT87bh95OGNyZPhcTNXj1NW7RuBCsyN/o0jlpcV8Qyq46cDfL" crossOrigin="anonymous"></script>

        <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/@tabler/icons-webfont@latest/tabler-icons.min.css" />
        <link href='https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css' rel='stylesheet' />

        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link href="https://cdn.quilljs.com/1.1.6/quill.snow.css" rel="stylesheet" />

        {/* <link href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap" rel="stylesheet" /> */}

        {/* <link href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap" rel="stylesheet"></link> */}

        {/* <link href="https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100;0,9..40,200;0,9..40,300;0,9..40,400;0,9..40,500;0,9..40,600;0,9..40,700;0,9..40,800;0,9..40,900;0,9..40,1000;1,9..40,100;1,9..40,200;1,9..40,300;1,9..40,400;1,9..40,500;1,9..40,600;1,9..40,700;1,9..40,800;1,9..40,900;1,9..40,1000&display=swap" rel="stylesheet" /> */}

        <link href="https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap" rel="stylesheet"></link>

      </Helmet>

      <RecoilRoot>
        <ParallaxProvider>
          <div className="position-relative bg-tertiary" style={{ height: justOpened ? '100vh' : 'unset', minHeight: '100vh', overflowY: justOpened ? 'hidden' : 'unset' }}>
            {children}

            {justOpened &&
              // <main className={`position-absolute vh-100 w-100 bg-dark d-flex bg-white d-flex flex-column vh-100 justify-content-center align-items-center`} id="backdrop-loading" style={{top: '0', left: '0', zIndex: '999999999'}}>
              //   <div className="position-relative vh-100 vw-100">
              //     <div className="jumbo position-absolute vh-100 vw-100"></div>
              //   </div>

              //   <h1 className="vh-100 vw-100 position-absolute d-flex align-items-center justify-content-center fw-bold">
              //     <div className="shimmer fw-bold" style={{ letterSpacing: isMobile ? '10px' : '20px', fontSize: isMobile ? '30px' : '120px' }}>
              //       LIGHTBOX 
              //     </div>
              //   </h1>
              // </main> 
              <div></div>
            }
          </div>
        </ParallaxProvider>
      </RecoilRoot>
    </div>
  );
}

export default RootWrapper